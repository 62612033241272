<template>
  <span :class="className" @click.stop="$emit('handleClick')">
    {{ txt }}
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: ""
    },
    txt: {
      type: String,
      default: ""
    }
  }
};
</script>
