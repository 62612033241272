<template>
  <div-wrapper className="not-finddata">
    <p>{{ message }}</p>
  </div-wrapper>
</template>
<script>
import DivWrapper from '../form/DivWrapper.vue';
export default {
  components: { DivWrapper },
  props: {
    message: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_order.scss";
</style>
